@import 'c/main/src/constants';
@import 'c/main/src/type';

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.active {
        display: block;
        z-index: 11;
    }

    .modal-background {
        background-color: $blue-darker;
        opacity: 0.7;
        width: 100%;
        height: 100%;
    }

    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        min-width: 400px;
        height: auto;
        min-height: 300px;
        transform: translate3d(-50%, -50%, 0);
        background-color: $white;
        margin: auto;
        padding: 3em;
        border-radius: 5px;

        .form-text {
            margin-bottom: 1em;
        }

        .form-field {
            padding-bottom: 1em;
        }

        label {
            display: block;
            text-transform: capitalize;
        }

        .text-wrap,
        .email-wrap {
            width: 100%;
        }

        input {
            border-bottom: 1px solid $black;
            width: 100%;
        }

        input[type='submit'] {
            background: $blue;
            color: $white;
            padding: 1em 5em;
            width: auto;
            border: 1px solid $blue;
            display: block;
            margin-left: auto;

            &:hover,
            &:focus {
                background: $white;
                color: $blue;
                border: 1px solid $blue;
                outline: none;
            }
        }

        .download-link {
            a {
                color: $blue;
            }
        }
        svg {
            margin-left: 20px;

            #drift-copy {
                fill: $blue;
            }
        }
    }

    .modal-close {
        position: absolute;
        top: 1em;
        right: 1em;
        background: none;
        border: none;
        outline: none;
        width: 24px;
        height: 24px;
        margin: 0;
        padding: 0;
        cursor: pointer;

        &:before {
            content: ('');
            background: $black;
            transform: rotate(45deg) translate3d(0%, 0%, 0);
            transform-origin: center;
            height: 1px;
            width: 24px;
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
        }
        &:after {
            content: ('');
            height: 1px;
            width: 24px;
            background: $black;
            transform: rotate(-45deg) translate3d(0%, 0%, 0);
            transform-origin: center;
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
        }
    }
}