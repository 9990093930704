@import 'c/main/src/constants';

.map-canvas {
    &.default {
      background: $blue;
    }
  
    &.initialised,
    html.no-js & {
      background: none;
  
      .mapboxgl-canvas-container {
        visibility: inherit;
      }
    }
  
    .mapboxgl-canvas-container {
      visibility: hidden;
    }
  
    .leaflet-control {
      &.leaflet-disabled,
      .leaflet-disabled {
        display: none;
      }
    }
  
    .custom-marker {
      .marker-dot {
        // @include sohne(11.5px);
        background: $blue;
        border: 0;
        border-radius: 50%;
        color: $blue;
        line-height: 25px;
        text-align: center;
      }
    }
  
    .itinerary-day-marker,
    .listing-background-marker,
    .listing-detail-marker {
      .marker-dot {
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
  
    .itinerary-day-marker,
    .listing-background-marker {
      visibility: hidden;
  
      &.selected,
      &.current {
        visibility: inherit;
      }
  
      .marker-dot {
        width: 25px;
        height: 25px;
        margin-top: -12.5px;
        margin-left: -12.5px;
      }
    }
  
    .listing-detail-marker {
      .marker-dot {
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
      }
    }
  
    .itinerary-day-marker {
      &.selected {
        z-index: 2;
      }
    }
  
    .listing-background-marker {
      &.selected {
        z-index: 3;
      }
  
      &.current {
        z-index: 4;
  
        .marker-inner {
          position: absolute;
          height: 50000px;
          width: 50000px;
          border: 24925px solid rgba(0, 0, 0, .5);
          border-radius: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
        }
  
        .image {
          position: absolute;
          bottom: 100%;
          left: 100%;
          width: 195px;
          margin-left: -25px;
          margin-bottom: -25px;
          border-radius: 50%;
          border: 8px solid $white;
          visibility: inherit;
        }
      }
  
      .image {
        visibility: hidden;
      }
    }
  
    .town-marker {
      visibility: hidden;
  
      &.selected,
      &.current {
        visibility: inherit;
  
        &.hidden {
          visibility: hidden;
        }
      }
  
      .marker-dot {
        @include desktop-and-tablet-landscape {
          width: 42px;
          height: 42px;
        }
  
        @include tablet-portrait-and-phone {
          width: 30px;
          height: 30px;
        }
      }
    }


    .drift-marker {
        width: 18px;
        height: 18px;

        background: $blue;
        border-radius: 100%;
    }
  
    .mapboxgl-ctrl-logo {
      display: none;
    }
  }
  