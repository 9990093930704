.video-player {
    position: relative;
  
    &.video-js {
      &:focus,
      &:hover {
        .vjs-big-play-button {
          background-color: transparent;
          transition: none;
        }
      }
  
      .vjs-poster.element-child {
        position: relative;
        width: 100%;
  
        img {
          width: 100%;
          height: auto;
        }
      }
  
      .vjs-big-play-button {
        background-color: transparent;
        transition: none;
      }
  
      .vjs-control-bar {
        background-color: transparent;
      }
  
      .vjs-slider {
        background-color: transparentize(#fff, 0.5);
      }
  
      .vjs-load-progress {
        background-color: transparentize(#fff, 0.5);
  
        div {
          background-color: transparentize(#fff, 0.5);
        }
      }
    }
  
    &.vjs-has-started {
      .vjs-poster {
        display: block;
        visibility: hidden;
        opacity: 0;
      }
    }
  
    &.vjs-playing {
      .cursor-play {
        opacity: 0;
      }
    }
  
    @keyframes spinner-show {
      to {
        visibility: visible;
      }
    }
  
    @keyframes spinner-spin {
      100% {
        transform: rotate(360deg);
      }
    }
  
    @keyframes spinner-fade {
      0% {
        border-top-color: transparentize(#fff, 0.5);
      }
  
      20% {
        border-top-color: transparentize(#fff, 0.5);
      }
  
      35% {
        border-top-color: #fff;
      }
  
      60% {
        border-top-color: transparentize(#fff, 0.5);
      }
  
      100% {
        border-top-color: transparentize(#fff, 0.5);
      }
    }
  
    &.vjs-seeking,
    &.vjs-waiting {
      .vjs-loading-spinner {
        animation: spinner-show 0s linear 0.3s forwards;
  
        &::before,
        &::after {
          animation: spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
            spinner-fade 1.1s linear infinite;
        }
  
        &::after {
          animation-delay: 0.44s;
        }
      }
    }
  
    > * {
      z-index: 3;
    }
  
    .vjs-loading-spinner {
      border-color: transparentize(#fff, 0.5);
    }
  
    .vjs-tech {
      z-index: 1;
  
      .vjs-iframe-blocker,
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
  
      .vjs-iframe-blocker {
        z-index: 2;
      }
    }
  
    .vjs-error-display {
      display: none;
    }
  
    .vjs-mask,
    .vjs-tech {
      // Noscript fallback. Identical style to .vjs-tech so doesn't mess up
      // anything for the js version
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    .vjs-mask {
      z-index: 4;
    }
  
    .cursor-play {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }