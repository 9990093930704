@import 'c/main/src/constants';
@import 'c/main/src/type';
@import 'c/main/lib/mixins';

.main-menu {
  position: fixed;
  width: 100%;
  z-index: 10;
  bottom: 0;
  left: 0;
  @include Neue(22px);

  @include desktop-and-tablet {
    width: auto;
    height: 100%;
    top: 0;
  }

  &__container {
    display: none;
    background: $blue;
    color: $white;
    background-color: $blue;
    height: 100vh;
    flex-direction: column;
    justify-content: center;

    @include desktop-and-tablet {
      margin-left: $navbar;
      padding-right: 10em;
    }

    ul {
      display: flex;
      flex-direction: column;
      margin-left: 10%;
      margin-bottom: 2em;

      @media screen and (min-height: 600px) {
        margin-bottom: 40%;
      }

      li {
        color: $white;
        padding-bottom: 0.5em;
      }
    }
    .active-enter & {
      display: flex;
      transform: translateY(103%);
      @include desktop-and-tablet {
        transform: translateX(-103%);
      }
    }

    .active-enter-active & {
      display: flex;
      transform: translateY(0%);
      transition: transform 400ms;

      @include desktop-and-tablet {
        transform: translateX(0%);
      }
    }

    .active-enter-done & {
      display: flex;
      transform: translateY(0%);

      @include desktop-and-tablet {
        transform: translateX(0%);
      }
    }

    .active-exit & {
      display: flex;
      transform: translateY(0%);
      @include desktop-and-tablet {
        transform: translateX(0%);
      }
    }

    .active-exit-active & {
      display: flex;
      transform: translateY(103%);
      transition: transform 400ms;

      @include desktop-and-tablet {
        transform: translateX(-103%);
      }
    }

    .active-exit-done & {
      display: none;
      transform: translateY(103%);

      @include desktop-and-tablet {
        transform: translateX(-103%);
      }
    }
  }

  &__brochure {
    color: $white;
    margin-left: 10%;
    white-space: nowrap;
  }

  // &.active-enter-done {
  //   min-height: 100vh;
  // }

  .hamburger {
    $hamburger-width: 24px;
    $hamburger-height: $navbar / 3;
    $line-width: 20px;
    $line-height: 1px;
    $line-gap: 7px;
    $transition-time: 500ms;
    $active-selector: '&.active';
    @include hamburger(
      $hamburger-width,
      $hamburger-height,
      $line-width,
      $line-height,
      $line-gap,
      $transition-time: 500ms,
      $active-selector: '&.active'
    );

    .hamburger-inner {
      &::after,
      &::before {
        background-color: $white;
      }
    }

    &__container {
      display: flex;
      width: 100%;
      height: $hamburger-height * 3;
      justify-content: flex-end;
      align-content: center;
      // margin: $hamburger-height 0;
      padding: $hamburger-height $hamburger-height;
      background: $blue;
      color: $white;
      cursor: pointer;

      @include desktop-and-tablet {
        width: $hamburger-height * 3;
        height: 100%;
        justify-content: flex-start;
      }
    }
  }
  &.active-enter,
  &.active-enter-active,
  &.active-enter-done,
  &.active-exit,
  &.active-exit-active,
  &.active-exit-done {
    .hamburger__container {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;

      @include desktop-and-tablet {
        bottom: unset;
        top: 0;
      }
    }
  }
}
