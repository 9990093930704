html {
  overflow-y: scroll; // always show vertical scrollbar on window
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100%;

  @media screen and (min-width: 668px) {
    overflow-y: hidden;
  }
}

*,
*::before,
*::after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  min-height: 100%;
  position: relative;
  // overflow: hidden;
}

.structural {
  width: 100%;
  $max-width: getSharedVariable(MAX_WIDTH);
  @if ($max-width) {
    max-width: $max-width * 1px;
  }
  margin-left: auto;
  margin-right: auto;
  // assign left and right in case we fix one of these elements.
  left: 0;
  right: 0;
}

ul {
  padding-left: 0;

  li {
    list-style: none;
    margin-left: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

nav {
  position: absolute;
  bottom: 0;
  left: 0;

  @media screen and (min-width: 668px) {
    top: 0;
  }
}

$finalHeight: 100vh;
$finalWidth: 3 * $finalHeight;
$scrollBarHeight: 25px;
$sectionWidth: 120vw;
$maxSectionWidth: 2800px;

::-webkit-scrollbar {
  @media screen and (min-width: 668px) {
    width: $scrollBarHeight;
    height: $scrollBarHeight;
  }
}

::-webkit-scrollbar-button {
  @media screen and (min-width: 668px) {
    width: $scrollBarHeight;
    height: $scrollBarHeight;
  }
}

div {
  box-sizing: border-box;
}

.horizontal-scroll {
  @media screen and (min-width: 668px) {
    width: calc(#{$finalHeight} + #{$scrollBarHeight});
    height: $sectionWidth * 1.5;
    transform: rotate(-90deg) translateX(-$finalHeight);
    transform-origin: top left;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
  }

  @media screen and (min-width: 1280px) {
    height: 100vw;
  }

  & > .horizontal-scroll__block {
    $slideWidth: 200vh;
    @media screen and (min-width: 668px) {
      display: flex;
      flex-direction: row;
      width: calc(554vw + (#{$slideWidth} * 5) + (#{$scrollBarHeight} * 8) + 540vh);
      // width: calc(615vw + (#{$slideWidth} * 5) + (#{$scrollBarHeight} * 8) + 490vh); // Full width of site
      overflow-x: hidden;
      transform: rotate(90deg) translateY(-$finalHeight);
      transform-origin: top left;

      > * {
        width: calc(#{$slideWidth} + #{$scrollBarHeight});
        height: $finalHeight;
      }
    }

    @media screen and (min-width: 1280px) {
      width: calc(475vw + (#{$slideWidth} * 5) + (#{$scrollBarHeight} * 8) + 540vh); // Full width of site
    }
  }
}

#home {
  .content_block__subtitle {
    @media screen and (min-width: 668px) {
      width: 100vw;
    }
  }
}

.grecaptcha-badge { 
  visibility: hidden;
} 