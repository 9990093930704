@import 'c/main/src/constants';

.footer {
    background: $blue;
    min-height: 50vh;
    scroll-snap-align: end;
    position: relative;
    

    @include desktop-and-tablet {
        margin-bottom: -100vw;
        width: 100vw!important;
        z-index: 0;
    }

    .blue-line__container {
        @include desktop-and-tablet {
            left: 0;
            z-index: 1;
            position: absolute;
            width: $navbar * 10;
        }
    }

    .image .aspect-maintainer {
        width: 100vw!important;
        height: 100vh!important;
        padding: 0!important;

        img {
            object-position: 40% 50%;
            object-fit: cover;
            width: 100%;
            height: 100%;

            @include desktop-and-tablet {
                object-position: 50% 50%;
            }
        }
    }
}
