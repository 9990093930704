//
// Font-faces
//
@mixin webfont($name, $filename, $weight: 400, $style: normal) {
  // sass-lint:disable-all
  // see https://css-tricks.com/snippets/css/using-font-face/
  @font-face {
    font-family: "#{$name}";
    src: url('./' + $filename + '.eot');
    src: url('./' + $filename + '.eot?#iefix') format('embedded-opentype'),
         url('./' + $filename + '.woff2') format('woff2'),
         url('./' + $filename + '.woff') format('woff'),
         url('./' + $filename + '.ttf') format('truetype');
        //  url('./' + $filename + '.svg') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

// @include webfont('example', 'ExampleWeb-Light', 300);
// @include webfont('example', 'ExampleWeb-Regular', 400);
// @include webfont('example', 'ExampleWeb-RegularItalic', 400, italic);

@include webfont('Neue', 'helvetica-neue', 300);

@include webfont('NeueRegular', 'helvetica-neue-regular', 400);