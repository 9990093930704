@import 'c/main/src/constants';

a {
  cursor: pointer; // for anchors with no href

  &:focus {
    outline: none;
  }

  &:hover {
    color: $blue-darkest;
  }
}
