.image {
  position: relative;
  overflow: hidden;

  &.blank {
    img {
      visibility: hidden;
    }
  }

  &.centered {
    &.restrict-width,
    &.svg-fill-width {
      img {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.restrict-height,
    &.svg-fill-height {
      img {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .aspect-maintainer {
    height: 0;
    position: relative;

    img {
      display: block;
      position: absolute;
    }
  }

  // We can't simply use width: auto; height: auto; max-width: 100%;
  // max-height: 100%; because some versions of Firefox won't display the
  // image.
  &.restrict-width,
  &.svg-fill-width {
    img {
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      height: auto;
    }
  }
  &.restrict-height,
  &.svg-fill-height {
    img {
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      width: auto;
    }
  }
  .lazyload,
  .lazyloading {
    opacity: 0;
    min-width: 1px;
    min-height: 1px;
  }

  .lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
  }
}
