@import 'c/main/src/constants';

h1,
h2,
h3,
h4,
h5,
h6,
svg,
p,
li,
.site-page,
.main-footer,
.main-menu .main-menu__container,
.content_block,
.blue-line__container {
  opacity: 0;
  transition: opacity $transition-time;
  // will-change: opacity;
}
